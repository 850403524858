import React, { useMemo } from 'react';

import { getFirstImageFromContent } from '@/utility/lexicalContentHelper';
import { t } from '@/utility/localization';

import FeedContent from '@/features/Feed/pages/PostPublicPage/FeedContent';
import PostAuthorData from '@/features/Feed/pages/PostPublicPage/PostAuthorData';

import NextImage from '@/components/common/NextImage';
import NplToolTip from '@/components/common/NplToolTip';
import Icon from '@/components/npl/Icon';
import NPLBadge from '@/components/npl/NPLBadge';
import NPLButton from '@/components/npl/NPLButton';
import NPLIconButton from '@/components/npl/NPLIconButton';

import OptionMenuV2 from '@/pages/portal/products/components/OptionMenuV2';
import {
  VariableTypes,
  replaceVariables
} from '@/pages/portal/utils/common';

import { EntityPostCard } from '../EntityPostCard';
import { LockedBadge } from '../LockedBadge';
import { COMMUNITY_POSTS_STATUS } from '../constants';

const PostCardContent = ({
  announcement,
  communityName,
  singlePost,
  isLocked,
  fullView,
  showPostVisibilityType = true,
  user,
  showStatus,
  communityData,
  isManager = false,
  isPreviewed = false,
  onSelectedMemberDetails = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onPin = () => {},
  onUnpin = () => {},
  handleReport = () => {},
  onLockButtonClick = () => {},
  setAnnouncementToShare = () => {}
}) => {
  const {
    _id: postId,
    title,
    content,
    isPinned,
    author,
    visibilityType,
    mentionedProducts,
    status,
    blurImageUrl
  } = announcement || {};

  const isAuthor = user?._id === author?._id;
  const hasMentionedProduct =
    Array.isArray(announcement?.mentionedProducts) &&
    announcement.mentionedProducts.length > 0;
  const mentionedProduct = {
    ...mentionedProducts?.[0]?.productInfo,
    type: mentionedProducts?.[0]?.type
  };
  const locked = isLocked && visibilityType !== 'public';

  const isPinnedPreview = isPinned && !singlePost;
  const isPending = status === COMMUNITY_POSTS_STATUS.PENDING;
  const isRejected = status === COMMUNITY_POSTS_STATUS.REJECTED;
  const isApproved = status === COMMUNITY_POSTS_STATUS.APPROVED;

  const editorVariables = [
    {
      text: communityName,
      variable: VariableTypes.CommunityName
    },
    {
      text: t('friend'),
      variable: VariableTypes.MemberName
    },
    {
      text: `${author?.firstName} ${author?.lastName}`,
      variable: VariableTypes.CommunityManagerName
    }
  ];

  const handleDelete = (e) => {
    e?.stopPropagation();
    onDelete?.(postId);
  };

  const handleEdit = (e) => {
    e?.stopPropagation();
    onEdit?.(announcement);
  };

  const handlePin = (e) => {
    e?.stopPropagation();
    if (isPinned) {
      onUnpin?.(postId);
    } else {
      onPin?.(postId);
    }
  };

  const getProfileImageFormatted = (url) => {
    return {
      alt: 'profile image',
      mobileImgProps: {
        src: url,
        width: 32,
        height: 32,
        quality: 50,
        objectFit: 'cover'
      }
    };
  };

  // Check if post contains images in content.root.children or emailSentData.content.root.children

  // Get the image src from content.root.children or emailSentData.content.root.children
  const getImageSrc = () =>
    announcement?.content?.root?.children?.find(
      (child) => child.type === 'image'
    )?.src ||
    announcement?.emailSentData?.content?.root?.children?.find(
      (child) => child.type === 'image'
    )?.src;

  const renderPostImage = () => {
    if (locked && blurImageUrl) {
      return (
        <div className="flex justify-center items-center relative rounded-8 overflow-hidden">
          <NextImage
            alt="post image"
            mobileImgProps={{
              src: blurImageUrl,
              width: 64,
              height: 64,
              quality: 100,
              objectFit: 'cover'
            }}
            className="rounded-8 flex-shrink-0 w-64 h-64"
          />
          <div className="bg-npl-transparent-black-25 absolute top-0 bottom-0 left-0 right-0 m-auto"></div>
          <Icon
            name="lock-01"
            width={24}
            height={24}
            fill="#F5F5F5"
            className="absolute top-0 bottom-0 left-0 right-0 m-auto"
          />
        </div>
      );
    }
    if (firstImageInContent) {
      return (
        <NextImage
          alt="post image"
          mobileImgProps={{
            src: getImageSrc(),
            width: 64,
            height: 64,
            quality: 100,
            objectFit: 'cover'
          }}
          className="rounded-8 flex-shrink-0"
        />
      );
    }
    return null;
  };

  const announcementKebabOptions = useMemo(() => {
    const options = [
      {
        label: t('edit'),
        onClick: handleEdit,
        icon: 'edit',
        visibility: isAuthor && Boolean(onEdit)
      },
      {
        isDivider: true,
        visibility: isAuthor && status === COMMUNITY_POSTS_STATUS.APPROVED
      },
      {
        label: t('share'),
        onClick: () => setAnnouncementToShare(announcement),
        icon: 'share-01',
        visibility:
          announcement?.status === COMMUNITY_POSTS_STATUS.APPROVED &&
          Boolean(setAnnouncementToShare)
      },
      {
        label: isPinned ? t('unpin') : t('pin'),
        onClick: handlePin,
        icon: isPinned ? 'unpin' : 'pin',
        visibility: isManager && isApproved && Boolean(handlePin)
      },
      // {
      //   label: hideLikeCount ? t('show-like-count') : t('hide-like-count'),
      //   onClick: () => handleHideLinkCount(postId, !hideLikeCount),
      //   icon: hideLikeCount ? 'heart' : 'heart-hide',
      //   visibility: isAuthor && isManager
      // },
      // {
      //   label: hideImpressions
      //     ? t('show-view-count')
      //     : t('hide-view-count'),
      //   onClick: () => handleHideViewCount(postId, !hideImpressions),
      //   icon: hideImpressions ? 'eye' : 'eye-off',
      //   visibility: isAuthor && isManager
      // },
      {
        isDivider: true,
        visibility: isApproved
      },
      {
        label: t('report'),
        onClick: () => handleReport(postId),
        icon: 'info-circle',
        isDanger: true,
        visibility: !isAuthor && !isManager
      },
      {
        label: t('delete'),
        onClick: handleDelete,
        icon: 'trash-01',
        isDanger: true,
        visibility: isAuthor || (isManager && Boolean(onDelete))
      }
    ];

    return options?.filter((option) => option.visibility);
  }, [
    handleEdit,
    isAuthor,
    status,
    announcement,
    isPinned,
    handlePin,
    isManager,
    handleDelete,
    setAnnouncementToShare,
    handleReport,
    postId
  ]);

  const firstImageInContent = useMemo(
    () => getFirstImageFromContent(content),
    [content]
  );

  return (
    <div
      className={`c-PostCardContent relative bg-white-default 
      group-hover:cursor-pointer
    group-hover:border-npl-neutral-light-solid-4 group-hover:bg-npl-neutral-light-solid-2`}>
      {showStatus && isRejected && (
        <div className="absolute -top-2 right-0 flex flex-row gap-8 items-center">
          <NPLButton
            hierarchy="neutral_secondary"
            buttonText={t('edit')}
            size="sm"
            onClick={handleEdit}
          />
          <NPLIconButton
            onClick={handleDelete}
            icon="trash-01"
            size="sm"
            hierarchy="plain"
          />
        </div>
      )}
      {!isPending &&
        !isRejected &&
        (isManager || isAuthor) &&
        !isPreviewed && (
          <div className="absolute -top-12 -right-8">
            <OptionMenuV2
              options={[...announcementKebabOptions]}
              renderCustomButton={(onClick) => (
                <NPLButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (locked || !user) {
                      onLockButtonClick();
                      return;
                    }
                    onClick();
                  }}
                  rounded
                  leadIcon="dots-horizontal"
                  size="sm"
                  hierarchy="plain"
                />
              )}
            />
          </div>
        )}
      {isPinned && (
        <div className="mb-8 flex flex-row justify-between items-center">
          <div className="flex flex-row gap-8">
            <Icon
              name="pin-filled"
              width={16}
              height={16}
              fill="#1B1B1859"
            />
            <p className="text-label-md font-medium text-npl-text-tertiary-on-light">
              {t('pinned')}
            </p>
          </div>
          {locked && <LockedBadge />}
        </div>
      )}
      {showStatus && isPending && (
        <div className="w-fit mb-16">
          <NplToolTip text={t('under-review')}>
            <NPLBadge
              text={t('pending-approval')}
              type="neutral"
              size="sm"
            />
          </NplToolTip>
        </div>
      )}
      {showStatus && isRejected && (
        <div className="w-fit mb-16">
          <NPLBadge text={t('post-rejected')} type="danger" size="sm" />
        </div>
      )}
      <div
        className={`flex ${isPinnedPreview ? 'flex-row' : 'flex-col'} justify-between gap-8`}>
        <div>
          {isPinnedPreview ? (
            <div className="h-32 w-32 rounded-full">
              <NextImage
                {...getProfileImageFormatted(author?.profileImage)}
                className="h-32 w-32 rounded-full"
              />
            </div>
          ) : (
            <div className="flex justify-between">
              <PostAuthorData
                post={announcement}
                showPostVisibilityType={showPostVisibilityType}
                onSelectedMemberDetails={onSelectedMemberDetails}
              />
              {locked && (
                <div className="grow-0">
                  <LockedBadge />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col flex-grow">
          <div className="text-heading-sm font-semibold break-words line-clamp-2">
            {replaceVariables(title, editorVariables)}
          </div>
          <FeedContent
            content={content}
            isUnlockedView={!locked}
            previewContent={isPinnedPreview}
            feedCoverImageUrl={firstImageInContent || blurImageUrl}
            onLockButtonClick={onLockButtonClick}
            fullView={fullView}
            editorVariables={editorVariables}
            isLocked={locked}
            mentionedProducts={mentionedProducts}
            communityData={communityData}
          />
        </div>
        {isPinned &&
          !hasMentionedProduct &&
          !fullView &&
          renderPostImage()}
      </div>
      {hasMentionedProduct && !fullView && (
        <div className="relative w-full h-full mt-12">
          {mentionedProducts?.length > 1 && (
            <div className="absolute top-0 right-0">
              <NPLBadge
                text={`+${mentionedProducts?.length - 1}`}
                type="neutral"
                size="sm"
                isFullyRounded
              />
            </div>
          )}
          <EntityPostCard
            entity={mentionedProduct}
            communitySlug={communityData?.link}
            fullView
          />
        </div>
      )}
    </div>
  );
};

export default PostCardContent;
